import { useAuth0 } from "@auth0/auth0-react";
import { RotatingLines } from "react-loader-spinner";
import axiosClient from "../../services/apiClient";
import { addAccessTokenInterceptor } from "../../services/apiClient";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";

//import DatePicker from "./datePicker";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TabPanel from "../../components/TabPanel";

import UnitEventsTable from "../Events/unitEventsTable";
import { aggregateSessions, UnitEvents } from "./processEventsData";
import { buildEventsAllBuildings } from "../../services/eventsMockService";
import BuildingEventsTable from "../Events/buildingEventsTable";
//import jwt_decode from 'jwt-decode';

type Building = {
  LatLong: [number, number];
  buildingId: string;
  name: string;
};

function Loader() {
  return (
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  );
}

export default function Insights() {
  const [level, setLevel] = useState(0);
  const {isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [unitsEventsData, setUnitsEventData] = useState<{
    [key: string]: any[];
  }>({});
  const [buildingsEventData, setBuildingsEventData] = useState<any[]>([]);
  const [building, setBuilding] = useState<Building>({
    LatLong: [0, 0],
    buildingId: "",
    name: "",
  });

  const [tenantName,setTenantName] = useState(null);
  const [AreBuildingsLoading, setAreBuildingsLoading] = useState(false);

  const handleChange = (newValue: number) => {
    setLevel(newValue);
  };

  // Helper function to decode the JWT token
  function parseJwt (token:any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);   

  };

  useEffect(() => {
    if (
      buildingsEventData.length > 0 &&
      Object.keys(unitsEventsData).length > 0
    ) {
      setAreBuildingsLoading(false);
    }
  }, [buildingsEventData, unitsEventsData]);

  
  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
    
    const fetchTenantName = async () => {
      let response = await axiosClient.get("tenants/current");
      console.log(response.data.name)
      setTenantName(response.data.name);
    };
    fetchTenantName();


    const fetchIdTokenClaims = async () => {
      try {
        const claims = await getIdTokenClaims();
//        console.log(claims)
        const tenantId = claims?.['https://cooktopsafety.com/TenantId']; 
        // Now you have the tenantId, store it or use it as needed
//        console.log("Tenant ID:", tenantId);
      } catch (error) {
        console.error("Error fetching ID token claims:", error);
        // Handle the error appropriately
      }
    };

    fetchIdTokenClaims(); // Call the function to fetch the claims

    // const getDisplayName = async () => {
    //   if (isAuthenticated) {
    //     try {
    //       const accessToken = await getAccessTokenSilently();

    //       // Decode the access token to get the user information
    //       const decodedToken = parseJwt(accessToken);
    //       console.log(decodedToken)
          
    //       // axiosClient.get('https://cooktopsafety.us.auth0.com/userinfo', { // Assuming your axiosClient's base URL includes the Auth0 domain
    //       //   headers: {
    //       //       'Authorization': `Bearer ${accessToken}` 
    //       //   }
    //       // })
    //       // .then(response => {
    //       //     console.log('User Info:', response.data); 
    //       // })
    //       // .catch(error => {
    //       //     console.error('Error fetching user info:', error);
    //       // });

    //     } catch (error) {
    //       console.error("Error getting access token:", error);
    //     }

       


        
    //   }
    // };

    // getDisplayName();

    // const decodedToken = jwt_decode(idToken);
    // const tenantId = decodedToken['https://your-auth0-domain/tenantId'];
    setAreBuildingsLoading(true);
    const fetchProperties = async () => {
      let response = await axiosClient.get("sensor-groups?pageSize=10");
      return response.data.data;
    };
    const processPropertyData = async () => {
      let propertyData = await fetchProperties();
      const events = buildEventsAllBuildings(propertyData);

      fetchUnitEvents(events);
    };
    processPropertyData();
//  }, [isAuthenticated,getAccessTokenSilently]);
  }, [getAccessTokenSilently]);

  const fetchSessionsPerSensor = async (sensorName: any) => {
    let [cookingSession, connectionStatus,stateChange,PCOstateChange,bootinfo] = await Promise.all([

      axiosClient.get(`telemetry/${sensorName}/sessions?pageSize=100`),
      axiosClient.get(`telemetry/${sensorName}/connectivity?pageSize=100`),
      axiosClient.get(`telemetry/${sensorName}/statechange?pageSize=100`),
      axiosClient.get(`telemetry/${sensorName}/PCOstatechange?pageSize=100`),
      axiosClient.get(`telemetry/${sensorName}/bootinfo?pageSize=100`),
    ]);


//    console.log(`connectionStatus: ${JSON.stringify(connectionStatus.data.data, null, 2)}`);
//    console.log(`connectionStatus: ${JSON.stringify(stateChange.data.data, null, 2)}`);
//    console.log(`connectionStatus: ${JSON.stringify(PCOstateChange.data.data, null, 2)}`);
//      console.log(`connectionStatus: ${JSON.stringify(bootinfo.data.data, null, 2)}`);

//    console.log(`stove status is: ${stoveStatus.data.data}`)
    return [cookingSession.data.data, connectionStatus.data.data, stateChange.data.data,PCOstateChange.data.data,bootinfo.data.data];

  };


  const fetchSensors = async (sensorGroupId: string) => {
    let response = await axiosClient.get(
      `sensor-groups/${sensorGroupId}/sensors?pageSize=10`
    );
    return response.data.data;
  };

  const fetchUnitEvents = async (buildingEvents: any) => {
    const getCookingSessions = async (buildingId: string) => {
      let sensorEvents: UnitEvents[] = [];
      let sensors = await fetchSensors(buildingId);
      let sensorNames = sensors.map((sensor: any) => sensor.name);
      let displayNames = sensors.map((sensor: any) => sensor.displayName);
      // console.log(`mysensors: ${JSON.stringify(sensors, null, 2)}`);

      for (let index = 0; index < sensorNames.length; index++) {
        const sensorName = sensorNames[index];
        const displayName = displayNames[index];

        let [sessionPerSensor, statusPerSensor, stateChangePerSensor,PCOstateChangePerSensor,bootinfoPerSensor] = await fetchSessionsPerSensor(sensorName);

        let status="disconnected";

        if(statusPerSensor.length > 0 && statusPerSensor[0]) 
        {  
          const thestate = statusPerSensor[0];
          status = thestate.eventType;
        }


        let stoveStatus = "NULL";

        if(stateChangePerSensor.length > 0 && stateChangePerSensor[0]) {  
          const thestate = stateChangePerSensor[0];
          if(thestate.currentStateEnum==6||thestate.currentStateEnum==9) {
            stoveStatus = "OFF";
          }
          else{
            stoveStatus = "ON";
          }
        }

        let isStoveInUse="NULL";

        if(stateChangePerSensor.length > 0 && stateChangePerSensor[0]) {  
          const thestate = stateChangePerSensor[0];
          if(thestate.prevStateEnum==0 && (
            thestate.currentStateEnum==1 || // monitoring
            thestate.currentStateEnum==2 || // warning
            thestate.currentStateEnum==3 || // fault state
            thestate.currentStateEnum==4)) {
            isStoveInUse = "YES";
          }  else {
            isStoveInUse = "NO";
          }
        }

        let isPCOconnected = "NULL";
        let stoveCurrent = "NULL";
        let PCOFWVersion = "NULL";
        let IsStoveRemoteUnlocked = "NULL"
//        console.log(`pco state change: ${JSON.stringify(PCOstateChangePerSensor, null, 2)}`);
        if(PCOstateChangePerSensor.length > 0 && PCOstateChangePerSensor[0]) 
        {  
          const thestate = PCOstateChangePerSensor[0];
          stoveCurrent = `${thestate.currentCurrentmA}`;
          PCOFWVersion = `${thestate.currentFWverMajor}.${thestate.currentFWverMinor}.${thestate.currentFWverPatch}`;
          IsStoveRemoteUnlocked = thestate.currentIsStoveRemoteUnlocked? "UNLOCKED":"LOCKED";
//          console.log(`the IsStoveRemoteUnlocked is: ${IsStoveRemoteUnlocked}`)
          if(thestate.currentUnitConnectedStatus==1)
          {
            isPCOconnected = "YES";
          }  else {
            isPCOconnected = "NO";
          }
        }



        let SCSFWVersion = "NULL";

        if(bootinfoPerSensor.length > 0 && bootinfoPerSensor[0]) 
        {  
            const thestate = bootinfoPerSensor[0];
            SCSFWVersion = thestate.firmwareVersion;
        }


//        console.log(`the SCSFWVersion is: ${SCSFWVersion}`)





        let unit = aggregateSessions(
          sensorName,
          displayName,
          sessionPerSensor,
          buildingId,
          buildingEvents,
          status,
          stoveStatus,
          isStoveInUse,
          isPCOconnected,
          stoveCurrent,
          PCOFWVersion,
          SCSFWVersion,
          IsStoveRemoteUnlocked,
        );
        sensorEvents.push(unit);
      }
      return sensorEvents;
    };

    let allBuildingUnitEvents: { [key: string]: any[] } = {};

    const promises = buildingEvents.map(async (buildingEvent: any) => {
      let sensorEvents = await getCookingSessions(buildingEvent.buildingId);
      let offlineSensors = sensorEvents.reduce((offlineUnits, sensorEvent) => {
        return offlineUnits + (sensorEvent["Status"] === "Offline" ? 1 : 0);
      }, 0);

      allBuildingUnitEvents[buildingEvent.buildingId] = sensorEvents;
      buildingEvent["offlineSensors"] = offlineSensors;
    });
    await Promise.all(promises);

    // setUnitsEventData(allBuildingUnitEvents);
    // setBuildingsEventData(buildingEvents);

    // let fakebuildingEvents = [
    //   {
        
    //     LatLong: [43.76972151913501, -79.67666829504532],
    //     buildingId: "e4e1985b-4dc8-4c43-a9f0-907e1fee01f5",
    //     name: "32 Hampton Springs, Brampton, Ontario",
    //     offlineSensors: 0,
    //   },
    //   {
    //     LatLong: [44.274024632759144, -76.44428299636188],
    //     buildingId: "82675cb6-7c6d-475d-931e-7d2ac90dcd6d",
    //     name: "496 Discovery Ave, Unit 11, Kingston, ON K7K 7E9",
    //     offlineSensors: 0,
    //   },
    //   {
    //     LatLong: [44.26983766240383, -76.4523194164025],
    //     buildingId: "8932966d-69aa-402e-9b46-07a63b507d18",
    //     name: "1206 15, #8a, Kingston, ON K7L 0C4",
    //     offlineSensors: 0,
    //   },
    // ];
    // let allBuildingUnitEvents = {
    //   "e4e1985b-4dc8-4c43-a9f0-907e1fee01f5": [
    //     {
    //       AlarmResponses: 0,
    //       Alarms: 0,
    //       AvgSessionLength: 1206.41,
    //       ExposedBurnerAccTime: 27,
    //       Fire: 0,
    //       MaxSessionIntensity: 0,
    //       NumberOfSessions: 100,
    //       PresenceCounter: 1292,
    //       PresenceRatio: 0.34,
    //       SensorID: "CTS_SCS_GEN_5_bb9fb0",
    //       Status: "Online",
    //       TooHotAccTime: 1738,
    //       UnitNumber: "apt# 100",
    //       Warnings: 0,
    //       address: undefined,
    //       buildingId: "e4e1985b-4dc8-4c43-a9f0-907e1fee01f5",
    //     },
    //   ],
    //   "82675cb6-7c6d-475d-931e-7d2ac90dcd6d": [
    //     {
    //       AlarmResponses: 0,
    //       Alarms: 0,
    //       AvgSessionLength: 1206.41,
    //       ExposedBurnerAccTime: 27,
    //       Fire: 0,
    //       MaxSessionIntensity: 0,
    //       NumberOfSessions: 100,
    //       PresenceCounter: 1292,
    //       PresenceRatio: 0.34,
    //       SensorID: "CTS_SCS_GEN_5_b992f0",
    //       Status: "Online",
    //       TooHotAccTime: 1738,
    //       UnitNumber: "apt# 707",
    //       Warnings: 0,
    //       address: undefined,
    //       buildingId: "82675cb6-7c6d-475d-931e-7d2ac90dcd6d",
    //     },
    //   ],
    //   "8932966d-69aa-402e-9b46-07a63b507d18": [
    //     {
    //       AlarmResponses: 0,
    //       Alarms: 0,
    //       AvgSessionLength: 1206.41,
    //       ExposedBurnerAccTime: 27,
    //       Fire: 0,
    //       MaxSessionIntensity: 0,
    //       NumberOfSessions: 100,
    //       PresenceCounter: 1292,
    //       PresenceRatio: 0.34,
    //       SensorID: "CTS_SCS_GEN_5_abb238",
    //       Status: "Online",
    //       TooHotAccTime: 1738,
    //       UnitNumber: "apt# 203",
    //       Warnings: 0,
    //       address: undefined,
    //       buildingId: "8932966d-69aa-402e-9b46-07a63b507d18",
    //     },
    //   ],
    // };
    setUnitsEventData(allBuildingUnitEvents);
    setBuildingsEventData(buildingEvents);
  };

  useEffect(() => {
    if (building.buildingId !== "") {
      setLevel(1);
    }
  }, [building]);

  const handleLoadUnitsData = (selectedBuilding: string) => {
    if (selectedBuilding === building.buildingId) {
      // No need to set the building again, we are returning to the same building
      setLevel(1);
      return;
    }
    for (let index = 0; index < buildingsEventData.length; index++) {
      if (buildingsEventData[index].buildingId == selectedBuilding) {
        setBuilding(buildingsEventData[index]);
        break;
      }
    }
  };

  return (
    <div className="container mx-auto mt-32 mb-60 overflow-x-scroll ">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        {/*        <DateRangeSelector setInterval={handleIntervalChange} />*/}
        {level === 1 && (
          <div className="inline-block float-right ml-9 mt-3">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => handleChange(0)}
            >
              {" "}
              Properties
            </Button>
          </div>
        )}
        <TabPanel value={level} index={0}>
          {
            <>
              {AreBuildingsLoading ? (
                <Loader />
              ) : (
                <BuildingEventsTable
                  data={buildingsEventData}
                  loadUnitsData={handleLoadUnitsData}
                  organization={tenantName}
                />
              )}
            </>
          }
        </TabPanel>
        <TabPanel value={level} index={1}>
          {
            <>
              {
                <UnitEventsTable
                  data={unitsEventsData[building.buildingId]}
                  building={building}
                />
              }
            </>
          }
        </TabPanel>
      </Box>
    </div>
  );
}
