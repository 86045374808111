import {useTable, useSortBy, usePagination, useFilters, useGlobalFilter,} from "react-table";
import axiosClient from "../../services/apiClient";
import React, { useState, useEffect } from "react";
import SingleStackedBar from "../../components/SingleStackedHorizontalBarGraph";
import { GlobalFilter } from "./buildingEventsTable";
import Map from "../../components/SelectableMap";

export default function UnitEventsTable(props) {

  const [CtsConfig, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   if (CtsConfig) { // Check if CtsConfig is not null
  //     console.log("Updated CtsConfig:", CtsConfig);

  //   }
  // }, [CtsConfig]); // This useEffect will run whenever CtsConfig changes

  useEffect(() => {

    async function getCtsConfig(groupid) {
      let GroupId = groupid;
      try {
        const response = await axiosClient.get(`ctsconfig/${GroupId}`);
        if (response.status != 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        else{
          setData(response.data);
        }
      }
      catch (err) {
        setError(err);
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    }




    getCtsConfig(props.building.buildingId); // Call the async function
  }, [props.building.buildingId]); // The empty dependency array ensures this runs only once on mount



  // @ts-ignore
  const graphData = ({ value, columnProps }) => {
    return (
      <div className="w-96 block">
        <SingleStackedBar data={value} />
      </div>
    );
  };


  const center = props.building.LatLong

  const markers = [
    {
      id: 1,
      position: props.building.LatLong,
      title: "Cambridge City Hall"
    }
  ];

  const sortGraph = (rowA, rowB) => {
    if (
      rowA.original.intensity +
        rowA.original.distraction +
        rowA.original.forgetfulness >
      rowB.original.intensity +
        rowB.original.distraction +
        rowB.original.forgetfulness
    )
      return 1;
    if (
      rowB.original.intensity +
        rowB.original.distraction +
        rowB.original.forgetfulness >
      rowA.original.intensity +
        rowA.original.distraction +
        rowA.original.forgetfulness
    )
      return -1;
    return 0;
  };

  const handleRowClick = (row) => {
    // props.loadBuildingData(row.buildingId);
  };

  // const displayAverage = ({ value, columnProps }) => {
  //   return <div>{value}</div>;
  // };

  const columns = React.useMemo(
    () => [
      {
        Header: "Unit",
        accessor: "UnitNumber", // accessor is the "key" in the data
        filter: "fuzzyText",
        isVisible: true,
      },
      {
        Header: "Sensor ID",
        accessor: "SensorID", // accessor is the "key" in the data
        filter: "fuzzyText",
        isVisible: true,
      },
      {
        Header: "Sensor Connection",
        accessor: "Status", // accessor is the "key" in the data
        Cell: ({ value }) => {
          return (
            <div
              style={{
                color: value === "Online" ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {value}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "PCO connection",
        accessor: "isPCOconnected", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div 
            style={{
              color: row.original.Status === "Online"?(row.original.isPCOconnected === "YES" ? "green" : "red"):"black",
              fontWeight: row.original.Status === "Online"?"bold":"normal",
            }}
            >
            {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"?"Online":"Offline"): "N/A"}
            </div>
          );
        },
        isVisible: CtsConfig?.CTSConfiguration["Dashboard.unitEventsPage"]?.Table?.Columns?.IsPCOconnected?.isVisible,
      },
      {
          Header: "Stove Power",
          accessor: "TurnOffStove", // accessor is the "key" in the data
          Cell: ({ row }) => {
            const [isPressed, setIsPressed] = React.useState(false);
            const [isHovered, setIsHovered] = React.useState(false);

            const buttonStyles = {
              backgroundColor: row.original.Status === "Online"? (row.original.isPCOconnected==="YES"?(row.original.stoveStatus === "ON"? "green": "red"):"green"): "grey",
              color: "white",
              fontWeight: "bold",
              border: "none",
              padding: "5px 10px",
              cursor: "pointer",
              borderRadius: "5px",
              display: "inline-block",
              width: '100%',
              transition: 'all 0.1s ease', // Add transition here
              boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.9), 1px 1px 0px rgba(255, 255, 255, 0.2) inset"
             };

            const activeStyles = {
              transform: 'translateY(2px)',
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2) inset', // Or adjust as needed
            };

            const hoverStyles = {
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 1), 2px 2px 0px rgba(255, 255, 255, 0.3) inset",
              backgroundColor: row.original.Status === "Online"? (row.original.stoveStatus === "ON"? "#45a049": "#c53c2f"): "grey", // Darker green/red on hover
           };            
            return (

              <td style={{ padding: '0', border: 'none', overflow: 'visible' }}> {/* Style the cell */}
                <button
                    disabled={row.original.Status!=="Online" || row.original.stoveStatus!== "ON" || row.original.isPCOconnected === "NO"}
                  //disabled={false}
                  style={{
                    ...buttonStyles,
                    ...(isPressed?activeStyles:{}),
                    ...(isHovered ? hoverStyles : {}),
                  }}
                  onMouseDown={() => setIsPressed(true)}
                  onMouseUp={() => setIsPressed(false)}
                  onMouseLeave={() => {setIsPressed(false); setIsHovered(false);}}
                  onMouseEnter={() => setIsHovered(true)}   // Set isHovered to true on mouse enter
                  onClick={() => handleTurnOffStove(row.original.SensorID)}
                >
                  {row.original.Status === "Online"? row.original.stoveStatus: "N/A"}
                </button>
              </td>
            );
          },
          isVisible: true,
      },
      {
        Header: "Unlock Stove",
        accessor: "StoveRemoteUnlock", // accessor is the "key" in the data
        Cell: ({ row }) => {
          const [isPressed, setIsPressed] = React.useState(false);
          const [isHovered, setIsHovered] = React.useState(false);
          const buttonStyles = {
            backgroundColor: row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?(row.original.IsStoveRemoteUnlocked==="UNLOCKED"?"green":"#FF0000"): "grey"):"grey"):"grey",
            color: "white",
            fontWeight: "bold",
            border: "none",
            padding: "5px 10px",
            cursor: "pointer",
            borderRadius: "5px",
            display: "inline-block",
            width: '100%',
            transition: 'all 0.1s ease', // Add transition here
            boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.9), 1px 1px 0px rgba(255, 255, 255, 0.2) inset"
           };

          const activeStyles = {
            transform: 'translateY(2px)',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2) inset', // Or adjust as needed
          };

          const hoverStyles = {
            boxShadow: "5px 5px 10px rgba(0, 0, 0, 1), 2px 2px 0px rgba(255, 255, 255, 0.3) inset",
            backgroundColor: row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?(row.original.IsStoveRemoteUnlocked==="UNLOCKED"?"#45a049":"#b32000"): "grey"):"grey"):"grey"
            
         };            
//         console.log(`row: ${row.original.IsStoveRemoteUnlocked}`);
          return (
            <td style={{ padding: '0', border: 'none', overflow: 'visible' }}> {/* Style the cell */}
              <button
                disabled={row.original.Status!=="Online" || row.original.isPCOconnected !== "YES" || row.original.stoveStatus!== "OFF" || row.original.IsStoveRemoteUnlocked==="UNLOCKED"}
           //     disabled={false}
                style={{
                  ...buttonStyles,
                  ...(isPressed?activeStyles:{}),
                  ...(isHovered ? hoverStyles : {}),
                }}
                onMouseDown={() => setIsPressed(true)}
                onMouseUp={() => setIsPressed(false)}
                onMouseLeave={() => {setIsPressed(false); setIsHovered(false);}}
                onMouseEnter={() => setIsHovered(true)}   // Set isHovered to true on mouse enter
                onClick={() => handleStoveRemoteUnlock(row.original.SensorID)}
              >
                {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?row.original.IsStoveRemoteUnlocked:"N/A"):"N/A"):"N/A"}
              </button>
            </td>
          );
        },
        isVisible: true,
      },
      {
        Header: "Stove in use",
        accessor: "StoveInUse", // accessor is the "key" in the data
        Cell: ({ row }) => {
//          console.log(`row: ${JSON.stringify(row.original, null, 2)}`);
          return (
            <div >
            {row.original.Status === "Online"? row.original.isStoveInUse: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Stove Current (mA)",
        accessor: "stoveCurrent", // accessor is the "key" in the data
        Cell: ({ row }) => {
//          console.log(`row: ${JSON.stringify(row.original, null, 2)}`);
          return (
            <div >
            {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"?row.original.stoveCurrent:"N/A"): "N/A"}

            </div>
          );
        },
        isVisible: true,
      },

      {
        Header: "Sensor FW Version",
        accessor: "SCSFWVersion", // accessor is the "key" in the data
        Cell: ({ row }) => {
//          console.log(`row: ${JSON.stringify(row.original, null, 2)}`);
//            {row.original.Status === "Online"? row.original.SCSFWVersion: "N/A"}
          return (
            <div >
              {row.original.Status==="Online"?row.original.SCSFWVersion:"N/A"}



            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "PCO FW version",
        accessor: "PCOFWVersion", // accessor is the "key" in the data
        Cell: ({ row }) => {
//          console.log(`row: ${JSON.stringify(row.original, null, 2)}`);
          return (
            <div >
            {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"?row.original.PCOFWVersion:"N/A"): "N/A"}

            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Number of Sessions",
        accessor: "NumberOfSessions", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.NumberOfSessions: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Average Session Length (s)",
        accessor: "AvgSessionLength", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.AvgSessionLength: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Max Session Intensity",
        accessor: "MaxSessionIntensity", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.MaxSessionIntensity: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Warnings",
        accessor: "Warnings", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.Warnings: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Alarms",
        accessor: "Alarms", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.Alarms: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "User Alarm Responses",
        accessor: "AlarmResponses", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.AlarmResponses: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Presence Counter (s)",
        accessor: "PresenceCounter", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.PresenceCounter: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Presence Ratio",
        accessor: "PresenceRatio", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.PresenceRation: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Exposed Burner Accumulated Time (s)",
        accessor: "ExposedBurnerAccTime", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.ExposedBurnerAccTime: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Too Hot Accumulated Time (s)",
        accessor: "TooHotAccTime", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.TooHotAccTime: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Fire",
        accessor: "Fire", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.Fire: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
    ],
    [CtsConfig]
  );

  function handleTurnOffStove(sensorid) {
    //    console.log(`Turning off stove for unit: ${sensorid}`);
        axiosClient.post(`sensors/${sensorid}/shutdownstove`);
        // Implement the logic to turn off the stove for the unit with the given unitNumber
    
  }

  function handleStoveRemoteUnlock(sensorid){
      axiosClient.post(`sensors/${sensorid}/unlockstove`);
//      console.log("click unlock")
  }



  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      //fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, hiddenColumns },
    //Added TS ignore Outdated ts support in for this library
    // @ts-ignore
  } = useTable(
    {
      columns,
      data: props.data,
      initialState: {
        hiddenColumns: columns.filter((column) => !column.isVisible).map((column) => column.accessor),
        sortBy: [{ id: "UnitNumber" }],
        pageIndex: 0,
      },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

    useEffect(() => {
      // if(props.data.length>0)
      // {
      //   console.log(props.data)
      //   console.log(props.building.name)
      // }
   }, [props.data]); // Re-run whenever 'props.data' changes

  return (
    <>
      <div className="container m-auto mt-6">
        <h1 className="text-2xl">Property: {props.building.name}</h1>
        <div style={{ height: '20px' }} />
        <div className="container-layout">
          <div style={{ flexGrow: 0 }}>

          <table
            className="drop-shadow-lg"
            {...getTableProps()}
            style={{ border: "solid 1px black" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        // @ts-ignore
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          borderBottom: "solid 3px black",
                          background: "white",
                          color: "black",
                          fontWeight: "bold",
                          padding: "10px",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
              <tr>
                <th
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => handleRowClick(row.original)}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                            background: i % 2 === 0 ? "#EEEEEE" : "white",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>


          {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}


          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <>
              {Array.from({ length: 5 }, (_, i) => {
                const pageNumber = pageIndex + 1 + i;
                const isDisabled = pageNumber > pageOptions.length;

                return (
                  <button
                    key={pageNumber}
                    disabled={isDisabled}
                    onClick={() => !isDisabled && gotoPage(pageNumber - 1)}
                  >
                    {pageNumber}
                  </button>
                );
              })}
            </>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            {/* <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          </div>
          {/* END OF Pagination */}
        </div>
        <Map data={[props.building]} center={center} />
        </div>
        </div>
    </>
  );
}