interface PropertyEvents {
  buildingId: string;
  address: string;
  NumberOfSessions: number;
  AvgSessionLength: number;
  MaxSessionIntensity: number;
  Warnings: number;
  Alarms: number;
  AlarmResponses: number;
  PresenceCounter: number;
  PresenceRatio: number;
  ExposedBurnerAccTime: number;
  TooHotAccTime: number;
  EstimatedEnergyConsumption: number;
  AvgPowerPerSession: number;
  Fire: number;
  LatLong: [number, number]; // Tuple type for latitude and longitude
}

export interface UnitEvents {
  buildingId: string;
  address: string;
  UnitNumber: string;
  SensorID: string;
  NumberOfSessions: number;
  AvgSessionLength: number;
  MaxSessionIntensity: number;
  Warnings: number;
  Alarms: number;
  AlarmResponses: number;
  PresenceCounter: number;
  PresenceRatio: number;
  ExposedBurnerAccTime: number;
  TooHotAccTime: number;
  Fire: number;
  Status: string;
  stoveStatus: string;
  isStoveInUse: string;
  isPCOconnected:string;
  stoveCurrent:string;
  PCOFWVersion:string;
  SCSFWVersion:string;
  IsStoveRemoteUnlocked:string;

}

function getBuildingIndex(
  buildingId: string,
  buildingsEventData: PropertyEvents[]
) {
  var dataBuildingIndex = 0;
  for (var i = 0; i < buildingsEventData.length; i++) {
    if (buildingsEventData[i].buildingId === buildingId) {
      dataBuildingIndex = i;
      break;
    }
  }
  return dataBuildingIndex;
}

const calcTotalSessionsDuration = (sessions: []) => {
  let totalDuration = 0;
  sessions.forEach((session: any) => {
    totalDuration += session.durationSeconds;
  });
  return Math.round(totalDuration * 100) / 100;
};

const calcSessionPresenceDuration = (sessions: []) => {
  let presenceDuration = 0;
  sessions.forEach((session: any) => {
    presenceDuration += session.presence.durationSeconds;
  });
  return Math.round(presenceDuration * 100) / 100;
};

const calcSessionPresenceCount = (sessions: []) => {
  let presenceCount = 0;
  sessions.forEach((session: any) => {
    presenceCount += session.presence.count;
  });
  return presenceCount;
};

const calcSessionExposedDuration = (sessions: []) => {
  let exposedDuration = 0;
  sessions.forEach((session: any) => {
    exposedDuration += session.exposedBurner.durationSeconds;
  });
  return Math.round(exposedDuration * 100) / 100;
};

const calcSessionsTooHotPotDuration = (sessions: []) => {
  let tooHotPotDuration = 0;
  sessions.forEach((session: any) => {
    tooHotPotDuration += session.tooHotPot.durationSeconds;
  });
  return Math.round(tooHotPotDuration * 100) / 100;
};

const calcSessionFireCount = (sessions: []) => {
  let fireCount = 0;
  sessions.forEach((session: any) => {
    fireCount += session.fire.count;
  });
  return fireCount;
};

export const aggregateSessions = (
  sensorName: string,
  displayName: string,
  sessions: [],
  buildingId: string,
  buildingsEventData: PropertyEvents[],
  status: string,
  stoveStatus: string,
  isStoveInUse: string,
  isPCOconnected:string,
  stoveCurrent:string,
  PCOFWVersion:string,
  SCSFWVersion:string,
  IsStoveRemoteUnlocked:string,
) => {
  const unitIndex = getBuildingIndex(buildingId, buildingsEventData);
  const totalDuration = calcTotalSessionsDuration(sessions);
  let unit = {
    buildingId: buildingId,
    address: buildingsEventData[unitIndex].address,
    UnitNumber: displayName,
    SensorID: sensorName,
    NumberOfSessions: sessions.length,
    AvgSessionLength: Math.round((totalDuration / sessions.length) * 100) / 100,
    MaxSessionIntensity: 0, //TODO
    Warnings: 0, //TODO
    Alarms: 0, //TODO
    AlarmResponses: 0, //TODO
    PresenceCounter: calcSessionPresenceCount(sessions),
    PresenceRatio:
      Math.round(
        (calcSessionPresenceDuration(sessions) / totalDuration) * 100
      ) / 100,
    ExposedBurnerAccTime: calcSessionExposedDuration(sessions),
    TooHotAccTime: calcSessionsTooHotPotDuration(sessions),
    Fire: calcSessionFireCount(sessions),
    Status: status === "connected" ? "Online" : "Offline",
    stoveStatus: stoveStatus,
    isStoveInUse: isStoveInUse,
    isPCOconnected:isPCOconnected,
    stoveCurrent:stoveCurrent,
    PCOFWVersion:PCOFWVersion,
    SCSFWVersion:SCSFWVersion,
    IsStoveRemoteUnlocked:IsStoveRemoteUnlocked,
  };

  return unit;
};
