import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import React, { useState, useEffect } from "react";
import SingleStackedBar from "../../components/SingleStackedHorizontalBarGraph";
import Map from "../../components/SelectableMap";
import "./../Insights/tableStyle.scss";

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}
/*  see https://react-table.tanstack.com/docs/examples/filtering for sample
// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
*/
export default function BuildingEventsTable(props) {
  const [hoveredCoord, setHoveredCoord] = useState(null);

  const handleMouseEnter = (row) => {
    setHoveredCoord(row.LatLong);
  };

  const handleMouseLeave = () => {
    setHoveredCoord(null);
  };

  const sortAddress = (rowA, rowB) => {
    return rowA.original.address.localeCompare(
      rowB.original.address,
      undefined,
      {
        numeric: true,
      }
    );
  };

  const sortName = (rowA, rowB) => {
    return rowA.original.name.localeCompare(rowB.original.name, undefined, {
      numeric: true,
    });
  };

  const handleRowClick = (row) => {
    //    console.log("Row clicked", row);
    props.loadUnitsData(row.buildingId);
  };

  // const displayAverage = ({ value, columnProps }) => {
  //   return <div>{value}</div>;
  // };

  const columns = React.useMemo(
    () => [
      {
        Header: "Property Address",
        accessor: "name", // accessor is the "key" in the data
        sortType: sortName,
        filter: "fuzzyText",
        isVisible: true,
      },
      // {
      //   Header: "Address",
      //   accessor: "address", // accessor is the "key" in the data
      //   sortType: sortAddress,
      //   filter: "fuzzyText",
      // },
      {
        Header: "Offline Units",
        accessor: "offlineSensors", // accessor is the "key" in the data
        isVisible: true,
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      //fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  /*const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )*/

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize,hiddenColumns },

    //Added TS ignore Outdated ts support in for this library
    // @ts-ignore
  } = useTable(
    {
      columns,
      data: props.data,
      initialState: { 
        hiddenColumns: columns.filter((column) => !column.isVisible).map((column) => column.accessor),
        sortBy: [{ id: "name", desc: false }], 
        pageIndex: 0 
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (props.data.length > 0) {
      setHoveredCoord(props.data[0].LatLong);
    }
  }, [props.data]); // Re-run whenever 'props.data' changes

  return (
    <>
      <div className="container m-auto mt-6">
        <h1 className="text-2xl">{props.organization} Dashboard</h1>
        <div style={{ height: '20px' }} />
        <div className="container-layout">
{/*        <div style={{ display: "flex", justifyContent: "space-between" }}> */}
          <div style={{ flexGrow: 0 }}>
            <table
              className="building-table drop-shadow-lg"
              {...getTableProps()}
              style={{ border: "solid 1px black" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          // @ts-ignore
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            borderBottom: "solid 3px black",
                            background: "white",
                            color: "black",
                            fontWeight: "bold",
                            padding: "10px",
                          }}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      // globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </th>
                </tr>
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      onMouseEnter={() => handleMouseEnter(row.original)}
                      // onMouseLeave={handleMouseLeave}
                      onClick={() => handleRowClick(row.original)}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: "10px",
                              border: "solid 1px gray",
                              background: i % 2 === 0 ? "#EEEEEE" : "white",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
            <div className="pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>{" "}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <>
                {Array.from({ length: 5 }, (_, i) => {
                  const pageNumber = pageIndex + 1 + i;
                  const isDisabled = pageNumber > pageOptions.length;

                  return (
                    <button
                      key={pageNumber}
                      disabled={isDisabled}
                      onClick={() => !isDisabled && gotoPage(pageNumber - 1)}
                    >
                      {pageNumber}
                    </button>
                  );
                })}
              </>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
              {/* <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select> */}
            </div>
            {/* END OF Pagination */}
          </div>
          {hoveredCoord && (
            <Map
              data={props.data}
              center={hoveredCoord}
              loadUnitsData={props.loadUnitsData}
            />
          )}
        </div>
      </div>
    </>
  );
}
